/* *{
    font-family: "Raleway", sans-serif !important;
} */
  
body, html { height: 100% }

html {
    background: #010011;
    min-height: 100%;
    min-width: 100%;
    background-position: center;
    background-size: cover;
}

canvas {
    display:block;
    position: fixed;
    z-index: -1;
}